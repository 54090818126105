.container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 80%;
	margin: 0 auto;
	height: 100vh;
	align-items: flex-start;
	place-items: center;
}

/* // NEW FORM CHANGES STARTS */

.authContainer {
	display: grid;
	grid-template-columns: 1fr;
	width: 100%;
	position: relative;
	height: 100vh;
	align-items: flex-start;
	place-items: center;
}
.authFormContainer {
	max-width: 600px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;

	padding: 0 20px;
	box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;

	color: rgba(0, 0, 0, 0.87);
	transition: box-shadow var(--speed) cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow-wrap: break-word;
	background-color: rgb(255, 255, 255);
	border: 0.1px solid rgba(0, 0, 0, 0.125);
	border-radius: 1rem;
	margin: 30px auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.backgroundImage {
	width: 100%;
	object-fit: cover;
	object-position: center;

	height: 100%;
	background-position: center center;
}

/* // NEW FORM CHANGES ENDS */

.profile_container {
	display: flex;
	width: 80%;
	margin: 0 auto;
	height: 100vh;
	align-items: flex-start;
	justify-content: center;
	margin: 30px auto;
}

.formContainer {
	max-width: 600px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;

	padding: 0 20px;
	box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;

	color: rgba(0, 0, 0, 0.87);
	transition: box-shadow var(--speed) cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow-wrap: break-word;
	background-color: rgb(255, 255, 255);
	border: 0.1px solid rgba(0, 0, 0, 0.125);
	border-radius: 1rem;
	margin: 30px auto;
}
.containerbgImage {
	max-width: 100%;
	height: 100%;
	width: 100%;
	max-height: 450px;
}

.formHeader {
	margin: 20px 0;
}
.formHeaderSignUp {
	margin: 10px 0;
}
.linkContainer {
	margin: 20px 0;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}
.linkText {
	color: var(--brand);
}

.linkContainer > * + * {
	margin-top: 10px;
}

.termsaccepted {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	margin: 10px auto;
}

.termsaccepted a {
	color: var(--brand);
}

.deleteUserContainer {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	box-shadow: none;
	width: 100%;
	background: none;
	border: none;
}

@media screen and (max-width: 900px) {
	.img {
		display: none;
	}

	.containerbgImage {
		display: none;
	}
	.container {
		grid-template-columns: 1fr;
		place-items: flex-start;
	}
	.profile_container {
		padding: 5px;
	}
	.authFormContainer {
		width: 95%;
	}
}
@media screen and (max-width: 767px) {
	.deleteUserContainer {
		align-items: center;
	}
}

/* // GREATER THAN 1920 PX  */
@media (min-width: 1921px) {
	.containerbgImage {
		max-height: 600px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.formContainer {
		max-width: 800px;
	}
}
