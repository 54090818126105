/* https://codepen.io/yancy/pen/JZaYao */

.button {
	color: var(--black);
	cursor: pointer;

	padding: 10px 56px;
	text-decoration: none;
	border: none;
	font-size: 16px;
	border-radius: 12px;
	background-color: var(--card);
	transition: all var(--speed);
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(8%, #ffffff),
		color-stop(97%, #f2f2f7)
	);
	background-image: linear-gradient(-180deg, #ffffff 8%, #f2f2f7 97%);
	border: 1px solid hsla(220, 6%, 92%, 1);

	color: #3b426b;
	letter-spacing: 0.7px;
	text-align: center;
	line-height: 16px;
	font-weight: 700;
}

.button:focus {
	outline: none;
}

.button:hover,
.button:active {
	background: var(--accent);
	color: var(--white);
}

.button--inverse {
	background: var(--brand);
	color: var(--white);
	border: 1px solid var(--white);
}

.button--inverse:hover,
.button--inverse:active {
	/* background: linear-gradient(163deg, var(--brand), var(--brandHover)); */
	opacity: 0-7;
}

.button--danger {
	background: #830000;
	border-color: #830000;
	color: var(--white);
}

.button--danger:hover,
.button--danger:active {
	background: #f34343;
	border-color: #f34343;
	color: var(--white);
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
	background: var(--disabled);
	color: var(--white);
	cursor: not-allowed;
	transform: none;
	box-shadow: none;
}
