.subtitlesContainer {
  display: flex;
  gap: 5px;
}

.title {
  font-weight: bold;
  margin-right: auto;
  color: var(--black);
}

.container {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr;
  margin-top: 20px;
}
