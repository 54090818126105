.container {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

/* // sm */
@media (max-width: 640px) {
	.container {
		flex-direction: column;
	}
}
