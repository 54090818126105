@font-face {
	font-family: "BrandonGrotesque";
	font-weight: bold;
	src: url("/src/fonts/HVD\ Fonts\ -\ BrandonGrotesque-Bold.otf") format("opentype");
}

@font-face {
	font-family: "BrandonGrotesque Book";
	font-weight: lighter;
	src: url("/src/fonts/HVD\ Fonts\ -\ BrandonGrotesque-Light.otf") format("opentype");
}

@font-face {
	font-family: "BrandonGrotesque";
	font-weight: normal;
	src: url("/src/fonts/HVD\ Fonts\ -\ BrandonGrotesque-Medium.otf") format("opentype");
}

.thin {
	font-family: "BrandonGrotesque"; /* Light */
}

.thin-book {
	font-family: "BrandonGrotesque Book";
}

:root {
	--max-width: 1100px;
	--border-radius: 12px;
	--size-small: 10px;
	--size-medium: 20px;
	--size-large: 30px;
	--default-width: 88%;

	--background: rgb(248, 249, 250);
	--brand: #f39939;
	--accent: #ef7e04;
	--primary: #202124;
	--brandHover: #2b3037;
	--card: rgb(247, 248, 250);
	--disabled: grey;

	--white: white;
	--black: black;
	--red: #af1d1f;
	--error: #af1d1f;

	--speed: 500ms;
}

body,
*,
html {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	/* NOTE PUT OVERFLOW ONLY ON BODY NOT EVERYWHERE ELSE ->NAVBAR STICKY WILL NOT WORK IF PUT ANYWHERE ELSE */
	overflow-x: hidden;
	font-family: "BrandonGrotesque", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	max-width: 100vw;
	min-height: 100vh;
	background-color: transparent;
	overflow-x: hidden;
}
#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow-x: hidden;
}

a {
	color: inherit;
	text-decoration: none;
}

ul {
	list-style: none;
}
button {
	border: none;
	padding: 12px 56px;
}

.button-default {
	background-color: transparent;
	padding: 0;
	font-family: inherit;
	font-size: 100%;
}

button:hover {
	cursor: pointer;
}
.loader {
	height: 100vh;
}

.font-800 {
	font-size: 40px;
	font-weight: 600;
	opacity: 1 !important;
	margin-bottom: 20px;
}

.font-700 {
	font-size: 36px;
}

.font-600 {
	font-size: 24px;
}

.font-500 {
	font-size: 20px;
	line-height: 20px;
}

.font-400 {
	font-size: 18px !important;
}

.font-300 {
	font-size: 16px !important;
}

.font-200 {
	font-size: 14px !important;
}

.font-150 {
	font-size: 13px !important;
}

.font-100 {
	font-size: 12px !important;
}

.font-000 {
	font-size: 11px !important;
}

/* // padding  */

.pb-5 {
	padding-bottom: 5px !important;
}
.pb-10 {
	padding-bottom: 10px !important;
}
.pb-15 {
	padding-bottom: 15px !important;
}
.pb-20 {
	padding-bottom: 20px !important;
}
.pt-20 {
	padding-bottom: 20px !important;
}

.mt-20 {
	margin-top: 20px;
}
.mt-5 {
	margin-top: 5px;
}
/* /// width in PERCENT */

.width-80 {
	width: 80% !important;
}

.width-100 {
	width: 100% !important;
}

.display-flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.container {
	width: 94%;
	margin: 0 0 0 6%;
	padding: 10px 0 0 10px;
}
.secondary-container {
	width: 94%;
	margin: 0 auto;
	padding: 10px 0 0 10px;
}

.grid-container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 30px;
	column-gap: 20px;
	row-gap: 50px;
	/* margin-bottom: 100px; */
	margin-top: 20px;
}
.asset-main-container {
	width: 80%;
	margin: 0 auto;
}
.asset-main-container {
	padding: 10px;
}

.package-container {
	justify-content: center;
	display: flex;
	align-items: center;
}
/* // tablet */
@media (max-width: 768px) {
}

@media (max-width: 700px) {
}
@media (max-width: 640px) {
	.search-modal {
		top: 60%;
		padding: 50px 5px;
	}
	.container {
		width: 100%;
		margin: 0 auto;
	}
	h2 {
		font-size: 25px !important;
	}
}
/* // GREATER THAN 1920 PX  */
@media (min-width: 1921px) {
	.container,
	nav {
		width: 1920px !important;
		margin: 0 auto;
	}
	.grid-container {
		margin: 0 200px 0 200px;
	}
	.grid-container-promo {
		margin: 50px 0;
	}

	.asset-main-container {
		width: 60%;
	}
	.package-container {
		justify-content: center;
		display: flex;
		align-items: center;
	}
}

/* Change the color to your own background color when input is filled  */

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 50px var(--background) inset;
	-webkit-text-fill-color: var(--black);
}

a[target="_blank"]::after {
	background-color: var(--white);
	background-position: 50%;
	background-repeat: no-repeat;
	content: "";
	display: inline;
	margin-left: 0;
	-webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='currentColor' d='M7.064 0H2.022a.936.936 0 0 0 0 1.872h2.779L.272 6.401a.936.936 0 0 0 1.324 1.324l4.529-4.529v2.779a.936.936 0 0 0 1.873 0V.936A.936.936 0 0 0 7.064 0Z'/%3E%3C/svg%3E");
	mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='currentColor' d='M7.064 0H2.022a.936.936 0 0 0 0 1.872h2.779L.272 6.401a.936.936 0 0 0 1.324 1.324l4.529-4.529v2.779a.936.936 0 0 0 1.873 0V.936A.936.936 0 0 0 7.064 0Z'/%3E%3C/svg%3E");
	-webkit-mask-position: center;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
	-webkit-mask-size: 50%;
	mask-size: 50%;
	padding: 0 0.5em;
	position: relative;
	right: 0;
	top: 0;
	height: 18px;
	transition: 500ms;
}
