.container {
	grid-column: span 2;
	border-radius: var(--border-radius);
}

.containerImage {
	height: 100%;
	width: 100%;
	border-radius: var(--border-radius);
}

@media (max-width: 2560px) {
	.container {
		grid-column: span 2;
	}
}

@media (max-width: 1440px) {
	.container {
		grid-column: span 2;
	}
}
@media (max-width: 1200px) {
	.container {
		grid-column: span 3;
	}
}

@media (max-width: 1024px) {
	.container {
		grid-column: span 3;
	}
}

@media (max-width: 768px) {
	.container {
		grid-column: span 4;
	}
	.containerImage {
		height: 55%;
	}
}

@media (max-width: 650px) {
	.container {
		grid-column: span 6;
	}
}
@media (max-width: 425px) {
	.container {
		grid-column: span 12;
		padding: 20px;
	}
}
