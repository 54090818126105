.container {
	width: 80%;
	margin: 0 auto;
	margin-top: 100px;
	flex-direction: column;
	gap: 20px;
	justify-content: center;
	align-items: center;
	display: flex;
}

@media screen and (max-width: 640px) {
	.container {
		width: 95%;
	}
}

@media screen and (max-width: 400px) {
	.container {
		width: 100%;
		padding: 0 10px;
	}
}
