.promo-container {
	width: 100% !important;
}

.promo-container ul {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 5px;
}
.promo-carousel-item-padding-10-px {
	margin: 0 !important;
}

/* // if more than 1920 px */

@media (min-width: 1920px) {
	.promo-container {
		width: 100% !important;
	}
}

@media (max-width: 1440px) {
	.promo-container {
		margin: 0 0 20px 0 !important;
	}
	.promo-container ul {
		margin: 0 auto;
	}
}

@media (max-width: 768px) {
	.promo-container ul {
		margin: 0 auto;
	}
}
@media (max-width: 549px) {
	.promo-container {
		margin: 0 auto !important;
	}
	.promo-container ul {
		width: auto;
	}
}
