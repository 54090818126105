.container {
	min-width: 250px;
	border: 2px solid var(--brand);
	border-radius: var(--border-radius);
	min-height: 361px;
	padding: 20px;
	position: relative;
	width: 300px;
	height: 100%;
	/* height: 361px; */
}

.title {
	color: var(--brand);
	text-align: center;
	font-size: 1.5rem;
	font-weight: bold;
}

.price {
	color: var(--brand);
	text-align: center;
	font-size: 2rem;
	font-weight: bold;
	border-bottom: 1px solid var(--gray);
	margin-bottom: 20px;
	padding: 35px 0;
	word-wrap: break-word;
}

.description {
	text-align: center;
	font-size: 1rem;
	color: var(--black);
	width: 100%;
}
.textContainer {
	display: flex;
	margin-bottom: 50px;
	min-height: 120px;
	height: 100%;
}
.buyButtonAction {
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	bottom: 0;
}
