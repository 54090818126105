.image-item {
	margin: 5px;
	margin-right: 1rem;
}

.addSeecondary ul {
	transform: none !important;
}

.react-multiple-carousel__arrow {
	padding: 0 !important;
	background-color: var(--brand) !important;
	z-index: 100 !important;
}

/* // tablet */
@media (max-width: 768px) {
	.react-multi-carousel-list {
		overflow-x: auto !important;
	}
}
