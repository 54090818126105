.voucherTitle {
	font-size: 1.5rem;
	text-align: center;
	margin-bottom: 10px;
}

.voucherButtons {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 50px;
}

.voucherApiError {
	text-align: center;
	font-size: 1rem;
	margin-bottom: 10px;
	color: var(--red);
}
