.container {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: flex-end;
	position: relative;
}

.secondaryContainer {
	display: flex;
	position: absolute;
	width: 100vw;
	gap: 20px;
	inset: 0;
}

.imageContainer {
	display: flex;
	flex: 1;
	height: 100%;

	justify-content: center;
	align-items: center;
}

.coverImage {
	object-fit: contain;
	height: 80%;
	border-radius: 8px;
	filter: drop-shadow(30px 50px 50px grey);
	max-height: 450px;
}

.textContainer {
	display: flex;
	flex-direction: column;
	flex: 3;
	align-items: center;
}

/* // Not checked */

@media (max-width: 1000px) {
	.coverImage {
		width: 50%;
	}

	/* // NOT CHECKED */

	.secondaryContainer {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		max-height: 1200px;
		width: 100vw;
		top: 50px !important;
	}

	.imageContainer {
		width: 100%;
		height: 100%;
	}

	.textContainer {
		width: 100%;
		height: 100%;
		padding-left: 10px;
		padding-right: 10px;
		margin-top: 1%;
	}
}
@media (max-width: 600px) {
	.coverImage {
		height: 400px;
	}

	.secondaryContainer {
		width: 100vw;
	}
}

/* // GREATER THAN 1920 PX  */
@media (min-width: 1921px) {
	.coverImage {
		max-height: 100%;
		height: 80%;
	}
}
