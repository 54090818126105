.ps-sidebar-root {
	border: none !important;
	min-height: 100vh;
	height: 100%;
	background-color: var(--background);
}

.ps-sidebar-container {
	background-color: var(--background);
	z-index: 0 !important;
}
.ps-icon-wrapper {
	background-color: transparent !important;
}
.ps-container-item {
	padding: 5px 35px 5px 20px !important;
}

.ps-menu-button {
	background-color: transparent !important;
}

.ps-menu-button:hover {
	color: var(--accent) !important;
}
.ps-active .ps-menu-button {
	color: var(--brand) !important;
}
