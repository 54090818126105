.container {
	background-color: var(--white);
	color: var(--black);

	padding-bottom: 10px;
	border-radius: 10px;
	overflow: hidden !important;
	/* filter: drop-shadow(10px 10px 5px grey); */
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); /* Add a simple drop shadow */
}

.imageContainer {
	position: relative;
}

.assetImage {
	position: relative;
	aspect-ratio: 2 /3;
}
@supports not (aspect-ratio: 2/3) {
	.assetImage::before {
		float: left;
		padding-top: calc((2 / 3) * 100%);
		content: "";
	}

	.assetImage::after {
		display: block;
		content: "";
		clear: both;
	}
}

.assetImage img {
	aspect-ratio: 2 /3;
}

.assetImage:hover {
	cursor: pointer;
}
@supports not (aspect-ratio: 2/3) {
	.assetImage img::before {
		float: left;
		padding-top: calc((2 / 3) * 100%);
		content: "";
	}

	.assetImage img::after {
		display: block;
		content: "";
		clear: both;
	}
}
