.receiptView {
	margin: 30px auto;
}

.receiptTitle {
	color: var(--black);
	font-size: 1.5rem;
	margin: 10px auto;
	text-align: left;
}

.receiptText {
	margin: 0 auto;
}

.receiptFailureMessage {
	margin: 0 auto;
	color: var(--red);
}

.receiptReturnPath {
	margin: 10px auto;
	display: flex;
	justify-content: center;
	align-items: center;
}
