.container {
	display: flex;
	width: 100%;
	align-items: flex-end;
	justify-content: center;
}

.container span {
	width: 100% !important;
	/* // THIS IS FOR LAZY LOAD IMAGE */
}
.secondaryContainer {
	display: flex;
	position: absolute;
	width: 100%;
	margin: 0 auto;
}

.backgroundImage {
	display: block;
	width: 100%;
	object-fit: contain;
	object-position: center;
}

@media (max-width: 1024px) {
	.backgroundImage {
		/* display: none !important; */
		object-fit: cover;
		background-repeat: repeat;
		height: 1000px;
	}
}
