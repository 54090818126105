.imageContainer {
	grid-column: span 5;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.imageContainer img {
	width: 100%;
	height: 100%;
	border-radius: var(--border-radius);
	/* max-width: 350px; */
	max-width: 100%;
}

.container {
	background-color: var(--card);
}

.imageContainer:hover {
	cursor: pointer;
	opacity: 0.9;
}

/* // xl */
@media (max-width: 1280px) {
	.imageContainer {
		grid-column: span 6;
	}
}

/* // lg */
@media (max-width: 1024px) {
	.imageContainer {
		grid-column: span 7;
	}
}

/* // md */
@media (max-width: 768px) {
	.imageContainer {
		grid-column: span 12;
	}
}
