/* // tablet */

.textContainer {
	grid-column: span 7;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	padding: 20px;
}

.infoContainer,
.durationContainer {
	display: flex;
	gap: 10px;
}

.durationContainer {
	align-items: center;
}
.categoriesContainer {
	display: flex;
	gap: 10px;
}

.categories {
	background-color: var(--accent);
	padding: 5px 10px;
	border-radius: var(--border-radius);
	color: var(--white);
}

.description {
	line-height: 22px;
	color: var(--silver);
}

/* // xl */
@media (max-width: 1280px) {
	.textContainer {
		grid-column: span 6;
	}
}

/* // lg */
@media (max-width: 1024px) {
	.textContainer {
		grid-column: span 5;
	}
}

@media (max-width: 768px) {
	.textContainer {
		grid-column: span 12;
		padding: 0 20px 20px 20px;
	}
}
