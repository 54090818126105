.promoContainer {
  display: flex;
}
.promoContainer:hover {
  cursor: pointer;
}

.promoImage {
  width: 100%;
  height: 100%;
}

@media (max-width: 549px) {
  .promoContainer {
    flex-direction: column;
  }

  .promoImage {
    width: 100%;
  }
}
