.container {
	color: var(--black);
	margin: 0 auto;
	gap: 40px;
}

.inputContainer {
	display: flex;
	gap: 40px;
	margin: 0 auto;
	width: 100%;
}

.searchInput {
	padding: 15px 10px;
	border-radius: var(--border-radius);
	background-color: var(--card);
	border: none;
	outline: none;
	color: var(--black);
	transition: all var(--speed) ease-in-out;
}

.escIcon {
	flex-direction: column;
}

@media (max-width: 640px) {
	.container {
		width: 95% !important;
	}
}
