/* // THIS IS HEADER AND IT IS STICKY. NOTE ANY PARENT CANNOT HAVE ANY OVERFLOW BY DEFAULT ELSE IT WILL NOT WORK */
header {
	width: 100%;
	position: sticky;
	top: 0;
	z-index: 1000;
	transition: transform 150ms ease-in-out;
	height: 120px;
	border-bottom: 1px solid #cdd3d2;
	transition: var(--speed) all ease;
}

nav {
	display: flex;
	padding: var(--size-medium);
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
	width: 100%;
	margin: 0 auto;
	height: 100%;
}

.logo {
	object-fit: contain;
	max-width: 160px;
	min-width: 115px;
	width: 15vw;
}
.nav-container {
	display: flex;
	gap: 100px;
	z-index: 1;
	width: 100%;
	justify-content: space-between;
	height: 100%;
}

.nav__item {
	color: var(--black);
	font-weight: 500;
	text-transform: capitalize;
	/* // dont make this global */

	font-size: 18px;
}
.nav__item:hover {
	color: var(--brand) !important;
	opacity: 1;
}

/* // small size icon starts */
.nav__menu-bar {
	display: flex;
	flex-direction: column;
	row-gap: 6px;
	cursor: pointer;
	padding: 4px;
}
.nav__menu-bar svg {
	width: 20px;
	height: 20px;
	border-radius: 2px;
	z-index: 100;
}

/* // small size icon ends */

/* // CCATEGORIES CHEVRON ICON */
.categories-svg {
	width: 10px;
}

/* SMALL SCREEN NAVITEMS */

.nav__menu-list {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 80px;
	width: 288px;
	row-gap: 34px;
	right: -288px;
	padding: 24px 16px;
	transition: all var(--speed) ease;
	/* min-height: calc(100vh - 60px); */
	background-color: whitesmoke;
	z-index: 100;
}
.nav__menu-list.active {
	right: 0;
	transition: var(--speed) ease;
}

.nav__menu-list .active {
	/* color: var(--green); */
	color: var(--brand);
	/* border-bottom: 2px solid var(--brand); */
}
.nav__link {
	position: relative;
	transition: all var(--speed) ease;
}

.nav__link:hover {
	font-weight: bold;
}

header.nav-bar--hidden {
	transform: translateY(-100%);
}

/* BIG SCREEN NAVITEMS */

@media screen and (min-width: 769px) {
	nav {
		width: 80%;
		padding: var(--size-medium) 0;
	}
	.nav-container {
		width: unset;
	}
	.nav__menu-bar {
		display: none;
		opacity: 0;
	}
	.nav__menu-list {
		position: unset;
		flex-direction: row;
		min-height: fit-content;
		width: fit-content;
		column-gap: 34px;
		align-items: center;
		background-color: transparent;
		padding: 0;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
	.nav__link::before {
		content: "";
		position: absolute;
		width: 0%;
		height: 6px;
		bottom: -16px;
		left: 0;
		background-color: black;
		transition: all var(--speed) ease;
	}

	.nav__link:hover:before {
		width: 100%;
	}
	.nav__item {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
	}
}

/* // GREATER THAN 2920 PX  */
@media (min-width: 2921px) {
	header {
		height: 120px !important;
	}

	.nav__item {
		font-size: 34px;
	}
}

.nav__item_sidebar {
	/* // profile section navbar */
	justify-content: space-between;
}
