.privacyTermsContainer {
  margin: 2% auto 2% auto;
  width: 80%;
  flex-grow: 1;
  color: var(--mainBlack);
}

.privacyTermsTitle {
  font-weight: bold;
}

.item {
  margin: 12px 0;
  padding: 12px;
  border: var(--brand) solid 1px;
  border-radius: 12px;  
}

.privacyTermsMargin {
  margin-left: 2%;
}

.bolded {
  font-weight: bold;
}