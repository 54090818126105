.selectedPackage {
	margin: 10px auto;
	font-size: 1rem;
	color: var(--black);
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.selectedPackageRow {
	display: flex;
	gap: 100px;
}
.selectedPackageName {
	font-weight: 500;
}

.priceContainer {
	display: flex;
	flex-direction: column;
	gap: 5px;
}
