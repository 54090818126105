.packages {
	display: flex;
	margin: 30px 20px;
	gap: 50px;
}

.packageHelp {
	margin: 30px auto;
}

.packageHelpText {
	color: var(--black);
	text-align: left;
}

/* // tablet */
@media (max-width: 768px) {
	.packages {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.image {
	filter: grayscale(100%);
}
