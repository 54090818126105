.Overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 999999999;
}

.modal-login-container {
	height: 100% !important;
	grid-template-columns: 1fr !important;
	padding: 0 !important;
	border: none !important;
	box-shadow: none !important;
	margin: 0 auto !important;
	position: relative !important;
	top: auto !important;
	left: auto !important;
	right: auto !important;
	bottom: auto !important;
	transform: none !important;
}

@media (max-width: 640px) {
	.modal-login-container {
		padding: 0;
	}
}
