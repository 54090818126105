.text {
	color: var(--brand);
}

.inactiveText {
	color: var(--disabled);
}

.text,
.inactiveText {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
}

.text::after {
	content: "";
	border-bottom: 3px solid var(--brand);
	width: 20%;
	display: block;
	position: relative;
	width: 100%;
}
