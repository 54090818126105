.title {
	font-weight: 700;
	line-height: 33.6px;
	margin: 23px 0;
	font-size: 24px;
}

.titleContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.seasons {
	color: var(--brand);
	font-weight: 700;
}

.container {
	border-radius: var(--border-radius);
	margin: 0 20% 0 0;
	margin-top: 50px;
}

.description {
	margin: 23px 0;
	padding-bottom: 20px;
	font-size: 16px;
}

/* // GREATER THAN 2920 PX  */
@media (min-width: 1921px) {
	.description {
		font-size: 20px !important;
	}

	.title {
		font-size: 28px !important;
	}
}

/* // LESS THAN 1000PX */

@media (max-width: 1000px) {
	.container {
		background-color: var(--card);
		margin: 0 auto;
		padding: 30px 30px;
	}

	.titleContainer {
		border-bottom: 1px solid var(--brand);
	}
}
