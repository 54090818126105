.container {
	display: flex;
	gap: 5px;
	color: grey;
	font-size: 16px;
}
.divider {
	color: grey;
}

/* // GREATER THAN 2920 PX  */
@media (min-width: 1921px) {
	.extraClassname {
		font-size: 20px !important;
	}
}
