.GuidesBar {
  display: flex;
  justify-content: space-evenly;
  background-color: #141318;
  padding: 10px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
  align-items: center;
  justify-content: center;
  grid-column-gap: 30px;
  column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
  color: var(--white);
}

.GuidesBarItemContainer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.GuidesBarReadMore {
  font-size: var(--font14);
  color: #f87d3a;
}
