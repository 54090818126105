.menuTrigger,
.menuContainer {
	position: relative !important;
	background-color: transparent;
	font-family: inherit !important;
	padding: 0;
	/* // this is important */
}
.menuContainer:hover {
	cursor: pointer;
}

.menuTrigger {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.containerbgImage {
	margin-left: 10px;
}
/* // user name */
.title {
	margin: 20px 0;
	text-align: center;
	color: var(--brand);
}

/* // icon of user */

.menuTrigger img {
	top: -20px;
	height: 25px;
	width: 25px;
	/* border-radius: 50%; */
	overflow: hidden;
	cursor: pointer;
	background-color: var(--white);
}
/* // dropdown container */
.dropdown-menu {
	position: absolute;
	top: 50px;
	right: -10px;
	/* background-color: var(--background); */

	border-radius: var(--border-radius);
	/* padding: 10px; */
	/* width: 200px; */
}

.dropdown-menu::before {
	content: "";
	position: absolute;
	top: -5px;
	right: 20px;
	height: 20px;
	width: 20px;
	background: whitesmoke;
	transform: rotate(45deg);
}
/* // when open */
.activeDropdown {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
	transition: var(--speed) ease;
}
/* // when closed */
.inactiveDropdown {
	opacity: 0;
	visibility: hidden;
	transform: translateY(-20px);
	transition: var(--speed) ease;
}

/* // dropdown conainer when opened */
.dropdownItemsContainer {
	padding: 10px 0;
	border-top: 1px solid var(--background);
	gap: 20px;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dropdownItemsContainer__main {
	max-height: 300px;
	/* overflow: auto; */
	overflow: scroll;
	/* // WE ARE DOING SO USER WILL KNOW THEY CAN SCROLL */
}
/* // on hover of dropdown item */
.dropdownItemsContainer a:hover {
	color: var(--brand);
	cursor: pointer;
}

/* // WHEN LINNK IS JUST DIV INCASE OF LANGUAGE  */
.dropdownItemsContainer div:hover {
	color: var(--brand);
	cursor: pointer;
}

.dropdown-menu a:hover svg {
	opacity: 1;
	cursor: pointer;
}
/* // dropdown item like logout profile */
.dropdownItem {
	display: flex;
	background-color: transparent;
	width: 100%;
	justify-content: flex-start;
	padding: 0 10px;
	gap: 10px;
}
/* // dropdown item icon like icon beside profile */
.dropdownItem svg {
	max-width: 16px;
	margin-right: 10px;
	opacity: 0.5;
	transition: var(--speed);
}
