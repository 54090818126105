.container {
	display: flex;
	gap: 2px;
	flex-direction: column;
	margin-top: var(--size-small);
	justify-content: center;
	align-items: center;
	color: var(--black);
	margin: 0 auto;
	height: 80px;
}

/* // GREATER THAN 1441 PX  */
@media (min-width: 1441px) {
	.container {
		width: 80%;
	}
}
