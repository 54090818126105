.folderName {
    color: var(--black);
    border-radius: var(--border-radius);
    padding: 0px 5px 0px 0px;
}
.folderName:hover {
    cursor: pointer;
    color: var(--brand);
}

.folderNamesList {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 5px 5px 0px;
}
