.container {
  column-gap: 30px;
  row-gap: 30px;
  background: var(--card);
  display: flex;
}

.serieImage {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}
.container img:hover {
  cursor: pointer;
  opacity: 0.9;
}
.imageContainer {
  aspect-ratio: 16/9;
  width: 100%;
  height: 100%;
  flex: 0.5;
}

/* // tablet */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .imageContainer {
    flex: 1;
  }
}

/* // GREATER THAN 1441 PX  */
@media (min-width: 1441px) {
  .imageContainer {
    flex: 0.8;
  }
}
