.SignUpBarTitle {
  color: #ffffff;
  font-size: 32px;
  z-index: 2;
  margin-top: 2rem;
  font-weight: normal;
}

.SignUpBarSubtitle {
  color: #fffeff;
  font-size: 26px;
  z-index: 2;
  font-weight:lighter
}

.SignUpBarText {
  color: #fffeff;
  font-size: 22px;
  width: 65%;
  z-index: 2;
  margin-top: 2rem;
  text-align: center;
}

.SignUpBarInfoMessage {
  background: #f6c917;
  display: flex;
  font-size: 14px;
  z-index: 5;
  color: black;
  margin-top: 0.25rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.SignUpBarBackground {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  z-index: 1;
  padding: 0;
  margin: 0;
  background-color: #313745;
  background-image: linear-gradient(#313745, #1c1d1f);
}

.SignUpBar {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding-bottom: 2%;
  overflow: hidden;
  color: white;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #313745;
  background-attachment: fixed;
  background-image: linear-gradient(#313745, #1c1d1f);
}

.SignUpBarSignUpInput {
  padding: 0.5rem;
  width: 100%;
  margin-right: 0.25rem;
  background-color: #58575d;
  border: none;
  border-radius: 0.25rem;
  color: white;
  margin-bottom: 0.5rem;
}

.SignUpBarSignUpBTN {
  background-color: #f49a39;
  color: white;
  border-radius: 0.25rem;
  
}

.SignUpBarSignUpBTN:hover {
  background-color: #ffa13c;
  color: white;
}
@media (max-width: 900px) {
  .SignUpBarSignUpBTN {
    padding: 5px 10px;
    width: 100%;
  }
  
}

.SignUpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 3%;
  z-index: 2;
  height: 100%;
}

.SignUpContainer {
  width: 20%;
}
