.backgroundImageWrapper {
	display: flex;
	width: 100%;
	height: 690px;
	position: relative;
}

.gradientContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		190deg,
		rgba(255, 255, 255, 0.69) 0%,
		rgba(255, 255, 255, 0.87) 67%,
		rgba(0, 0, 0, 0.5) 100%
	);
}

.container {
	position: relative;
	display: inline-block;
	width: 100%;
}

.backgroundImage {
	width: 100%;
	object-fit: cover;
	object-position: center;

	height: 100%;
	background-repeat: no-repeat;

	/* background-repeat: no-repeat; */
}

@media (max-width: 1000px) {
	.backgroundImageWrapper {
		min-height: 1300px;
		height: 1300px;
	}
}

@media (max-width: 600px) {
	.backgroundImageWrapper {
		min-height: 1300px;
		height: 100%;
	}
}
