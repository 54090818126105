.card {
	max-width: 100%;
	display: block;
	width: 350px;
	background: linear-gradient(
		135deg,
		rgba(145, 99, 164, 0.8995799003195029) 0%,
		rgba(100, 76, 123, 0.9023810207676821) 100%
	);

	border-radius: var(--border-radius);
	color: var(--white);
	max-height: 450px;
	min-height: 450px;
	height: 450px;
}

.container {
	width: 80%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding: 20px 0;
}

.title::after {
	content: "";
	display: block;
	width: 100%;
	height: 1.5px;
	background-color: #9a76b0;
	margin-top: 5px;
}
.button {
	background-color: #d89bff;
	border-radius: calc(var(--border-radius) * 2);
	padding: 20px;
	font-weight: bold;
	font-size: 16px;
}
.button:hover {
	background-color: #9a76b0;
	color: var(--white);
}
/* // xl */
@media (max-width: 1280px) {
	.card {
		width: 35vw;
	}
}

/* // lg */
@media (max-width: 1024px) {
	.card {
		min-width: 300px;
		width: 40vw;
	}
}

/* // md */
@media (max-width: 768px) {
	.card {
		width: 40vw;
		min-width: 200px;
		min-height: 475px;
		height: 475px;
		max-height: 475px;
	}
}

/* // sm */
@media (max-width: 640px) {
	.card {
		width: 90vw;
	}
}
