.descriptionContainer {
	flex: 2;
	justify-content: space-between;
	min-height: 50px;
	padding: 0 10px 0 0;
	display: flex;
	gap: 10px;
	margin-bottom: 20px;
}
.packageDescription {
	color: var(--disabled);
	font-size: 16px;
}

.container {
	display: flex;
	flex-direction: column;
	flex: 2;
}

/* // tablet */
@media (max-width: 768px) {
	.container {
		padding: 0 10px;
	}
}

/* // GREATER THAN 2920 PX  */
@media (min-width: 1921px) {
	.packageDescription {
		font-size: 20px !important;
	}
}
