@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/violet.css";

.ScrollAreaRoot {
	width: 200px;
	height: 300px;
	border-radius: var(--border-radius);
	overflow: hidden;
	box-shadow: 0 2px 10px var(--black-a7);
	background-color: white;
	--scrollbar-size: 10px;
}

.ScrollAreaViewport {
	width: 100%;
	height: 100%;
	border-radius: inherit;
}

.ScrollAreaScrollbar {
	display: flex;
	/* ensures no selection */
	user-select: none;
	/* disable browser handling of all panning and zooming gestures on touch devices */
	touch-action: none;
	padding: 2px;
	background: var(--black-a6);
	transition: background 160ms ease-out;
}
.ScrollAreaScrollbar:hover {
	background: var(--black-a8);
}
.ScrollAreaScrollbar[data-orientation="vertical"] {
	width: var(--scrollbar-size);
}
.ScrollAreaScrollbar[data-orientation="horizontal"] {
	flex-direction: column;
	height: var(--scrollbar-size);
}

.ScrollAreaThumb {
	flex: 1;
	background: var(--mauve-10);
	border-radius: var(--scrollbar-size);
	position: relative;
}
/* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
.ScrollAreaThumb::before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	min-width: 44px;
	min-height: 44px;
}

.ScrollAreaCorner {
	background: var(--black-a8);
}

.Tag {
	color: var(--mauve-12);
	line-height: 18px;
	margin-top: 10px;
	border-top: 1px solid var(--mauve-6);
	padding-top: 10px;
	cursor: pointer;
}
.title {
	line-height: 18px;
	margin: 20px 0;
	text-align: center;
	color: var(--brand);
}
