.tosContainer {
	margin: 2% auto 2% auto;
	width: 80%;
	flex-grow: 1;
	color: var(--black);
}
.tosContainer a {
	color: var(--brand);
}
.tosContainer a:hover {
	color: var(--brandHover);
}

.tosTitle {
	font-weight: bold;
}

.item {
	margin: 12px 0;
	padding: 12px;
	border: var(--brand) solid 1px;
	border-radius: 12px;
}

.tosMargin {
	margin-left: 2%;
}

.tosImage {
	/* width: 100%; */
	max-width: 100%;
}
