.container {
	padding: var(--size-large) var(--size-small) 0 var(--size-medium);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title {
	font-weight: 500;
	/* text-transform: capitalize; */
}
