.container {
	display: flex;
	justify-content: space-between;
	padding: 10px 10px 0 0;
	margin-bottom: 10px;
}

.title {
	font-size: 18px !important;
}

/* // GREATER THAN 2920 PX  */
@media (min-width: 1921px) {
	.title {
		font-size: 24px !important;
	}
}
