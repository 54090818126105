.loggedInContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px 0 !important;
}

.loggedInMessage {
	display: flex;
	flex-direction: column;
	border: 2px solid whitesmoke;
	padding: 20px 50px;
	border-radius: var(--border-radius);
}

.loggedInEmail {
	color: var(--brand);
	font-style: italic;
}

.container {
	display: flex;
	gap: 50px;
}

.movieTitle {
	color: var(--brand);
}
/* // tablet */
@media (max-width: 768px) {
	.container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
	}
}
