.container {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
  margin-bottom: 8px;
  margin-top: 16px;
  opacity: 1;
  background: var(--background);
  color: rgb(52, 71, 103);
  border-radius: 0.75rem;
  grid-column: span 4;
  width: 100% !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 0.5rem 1.6875rem 0rem;
  border: 0.01px solid rgba(0, 0, 0, 0.125);
}

.validity {
  display: flex;
}

.subtitle {
  font-weight: bold;
}

.noplan {
  border: #d1d7dc dashed 2px;
  padding: 4.8rem;
  text-align: center;
  margin-top: 20px;
}
@media (max-width: 2560px) {
  .container {
    grid-column: span 3;
  }
}

@media (max-width: 1440px) {
  .container {
    grid-column: span 4;
  }
}
@media (max-width: 1200px) {
  .container {
    grid-column: span 4;
  }
}

@media (max-width: 1024px) {
  .container {
    grid-column: span 4;
  }
}

@media (max-width: 768px) {
  .container {
    grid-column: span 4;
  }
  .noplan {
    padding: 2.4rem;
  }
}

@media (max-width: 650px) {
  .container {
    grid-column: span 12;
  }
  .noplan {
    padding: 1.2rem;
  }
}
