.details_share {
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
}

.shareBTN {
	cursor: pointer;
	margin-right: 10px;
	z-index: 1 !important;
	position: relative;
}

.shareBTN:hover {
	opacity: 0.75;
}
